var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("user.inputPlayerId") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.uid,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "uid", $$v)
              },
              expression: "listQuery.uid"
            }
          }),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              type: "daterange",
              align: "right",
              "unlink-panels": "",
              "range-separator": _vm.$t("statManager.to"),
              "start-placeholder": _vm.$t("statManager.startTime"),
              "end-placeholder": _vm.$t("statManager.endTime"),
              "picker-options": _vm.pickerOptions
            },
            model: {
              value: _vm.rangeTime,
              callback: function($$v) {
                _vm.rangeTime = $$v
              },
              expression: "rangeTime"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: {
                placeholder: _vm.$t("gameSetting.fb_state"),
                clearable: ""
              },
              model: {
                value: _vm.listQuery.state,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "state", $$v)
                },
                expression: "listQuery.state"
              }
            },
            _vm._l(_vm.states, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: {
                  label: item.name + "(" + item.key + ")",
                  value: item.key
                }
              })
            }),
            1
          ),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("user.inputOperate") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.operate,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "operate", $$v)
              },
              expression: "listQuery.operate"
            }
          }),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.search")) + "\n    "
              )
            ]
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-sort" },
              on: { click: _vm.openBatchDeal }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("gameSetting.batchDeal")) + "\n    "
              )
            ]
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-success" },
              on: { click: _vm.batchDealPass }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("gameSetting.batchDealPassAndDeny")) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "sort-change": _vm.sortChange }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("agents.id"),
              prop: "id",
              sortable: "custom",
              align: "center",
              width: "100px",
              "class-name": _vm.getSortClass("id")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("user.playerId"),
              width: "100px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.uid))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("gameSetting.fb_state"),
              width: "120px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.getState(scope.row.state)))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("gameSetting.fb_content"),
              width: "270px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.content))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("gameSetting.fb_images"),
              width: "120px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.displayImage
                      ? _c("el-popover", { key: scope.row.headimgurl }, [
                          _c("img", {
                            staticStyle: { width: "500px", height: "500px" },
                            attrs: {
                              src:
                                scope.row.headimgurl +
                                "?x-oss-process=image/resize,h_100,m_lfit"
                            }
                          }),
                          _c("img", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: {
                              slot: "reference",
                              src:
                                scope.row.headimgurl +
                                "?x-oss-process=image/resize,h_100,m_lfit"
                            },
                            slot: "reference"
                          })
                        ])
                      : _vm._e(),
                    !_vm.displayImage
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.showReportDetail(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("gameSetting.fb_showDetail")) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("gameSetting.fb_createTime"),
              width: "120px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getTime(scope.row.createTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("gameSetting.fb_updateTime"),
              width: "120px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getTime(scope.row.updateTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("user.operate"),
              width: "120px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.operate))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: _vm.$t("default.operate"),
              "min-width": "160"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.state === 0 && !_vm.displayImage
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.handleReport(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("default.pass")) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    scope.row.state === 1 && !_vm.displayImage
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.handleReport(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("default.reject")) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.displayImage
                      ? _c("el-checkbox", {
                          model: {
                            value: scope.row.checked,
                            callback: function($$v) {
                              _vm.$set(scope.row, "checked", $$v)
                            },
                            expression: "scope.row.checked"
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                model: _vm.imageDetail,
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("agents.id"), prop: "id" } },
                [
                  _c("el-input", {
                    attrs: { readonly: true, type: "text" },
                    model: {
                      value: _vm.imageDetail.id,
                      callback: function($$v) {
                        _vm.$set(_vm.imageDetail, "id", $$v)
                      },
                      expression: "imageDetail.id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("user.playerId"), prop: "uid" } },
                [
                  _c("el-input", {
                    attrs: { readonly: true, type: "text" },
                    model: {
                      value: _vm.imageDetail.uid,
                      callback: function($$v) {
                        _vm.$set(_vm.imageDetail, "uid", $$v)
                      },
                      expression: "imageDetail.uid"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("gameSetting.fb_images"),
                    prop: "headimgurl"
                  }
                },
                [
                  _c("el-popover", { key: _vm.imageDetail.headimgurl }, [
                    _c("img", {
                      staticStyle: { width: "500px", height: "500px" },
                      attrs: {
                        src:
                          _vm.imageDetail.headimgurl +
                          "?x-oss-process=image/resize,h_100,m_lfit"
                      }
                    }),
                    _c("img", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: {
                        slot: "reference",
                        src:
                          _vm.imageDetail.headimgurl +
                          "?x-oss-process=image/resize,h_100,m_lfit"
                      },
                      slot: "reference"
                    })
                  ])
                ],
                1
              ),
              _vm.imageDetail.state === 0
                ? _c(
                    "el-form-item",
                    {
                      attrs: { prop: "type", label: _vm.$t("default.operate") }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("default.operate"),
                            clearable: ""
                          },
                          model: {
                            value: _vm.imageDetail.type,
                            callback: function($$v) {
                              _vm.$set(_vm.imageDetail, "type", $$v)
                            },
                            expression: "imageDetail.type"
                          }
                        },
                        _vm._l(_vm.types, function(item, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: {
                              label: _vm.$t(item.dec),
                              value: item.value
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    {
                      attrs: { prop: "type", label: _vm.$t("default.operate") }
                    },
                    [
                      _c("el-input", {
                        attrs: { readonly: true, type: "text" },
                        model: {
                          value: _vm.imageDetail.stateText,
                          callback: function($$v) {
                            _vm.$set(_vm.imageDetail, "stateText", $$v)
                          },
                          expression: "imageDetail.stateText"
                        }
                      })
                    ],
                    1
                  ),
              _vm.imageDetail.type === 2
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "content",
                            label: _vm.$t("gameSetting.fb_content")
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: _vm.$t(
                                "operation.inputRefuseContent"
                              )
                            },
                            model: {
                              value: _vm.imageDetail.content,
                              callback: function($$v) {
                                _vm.$set(_vm.imageDetail, "content", $$v)
                              },
                              expression: "imageDetail.content"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "title",
                            label: _vm.$t("gameSetting.title")
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("gameSetting.inputTitle")
                            },
                            model: {
                              value: _vm.imageDetail.title,
                              callback: function($$v) {
                                _vm.$set(_vm.imageDetail, "title", $$v)
                              },
                              expression: "imageDetail.title"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "mailContent",
                            label: _vm.$t("gameSetting.mailContent")
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: _vm.$t(
                                "gameSetting.inputMailContent"
                              )
                            },
                            model: {
                              value: _vm.imageDetail.mailContent,
                              callback: function($$v) {
                                _vm.$set(_vm.imageDetail, "mailContent", $$v)
                              },
                              expression: "imageDetail.mailContent"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.imageDetail.state === 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("default.operate") } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "0px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.handleReport(_vm.imageDetail)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("default.handler")) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }